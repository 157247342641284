$font-family_1: var(--font2);

.post-img {
	height: 404px;
	overflow: hidden;
	img {
		width: 100%;
	}
}
.blog-post-content {
	h1 {
		text-align: center;
	}
	p {
		font-size: 18px;
	}
}
.e{
	position: relative;
	left: 18px;
}
.extra{
	position: relative;
	top: -20px;
}
.ex{
	position: relative;
	left: 15px;
}
.ex1{
	position: relative;
	left: 10px;
}
.ex2{
	position: relative;
	left: 30px;
}
.ex3{
	position: relative;
	left: 40px;
}
.row {
	display: flex;
}
.more-post {
	flex-direction: column;
	align-items: center;
}
.blogpost-meta {
	display: flex;
	justify-content: space-between;
}
.author-info {
	margin: 12px 0;
	div {
		padding: 4px 0px;
		font-family: $font-family_1;
	}
}
.social {
	padding-right: 53px;
	align-self: center;
	cursor: pointer;
}
