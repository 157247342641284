@import "../../css-config/mixins.scss";

.main-content {
    max-width: calc(100% - 20px);
    margin: 0 auto;

    @include md {
        max-width: 1200px;
    }
}

@import "../../css-config/mixins.scss";

.home {
    background-color: #fafafa;
}

body {
    background-color: #ffffff;
}

.homepage {
    text-align: center;
}

.groceries-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
}

.groceries-list h2 {
    width: 100%;
    font-weight: bold;
    font-size: 35px;
    margin-bottom: 20px;
    text-align: center;
}

.groceries-list h3 {
    width: 100%;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.grocery-card .btn {
    border: none;
    background-color: #368942;
    color: #fafafa;
    font-weight: bold;
    width: 76%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 15px;
}

.grocery-card .btn:hover {
    background-color: #67C440;
    color: #fafafa;
    font-weight: bold;
}

.btn-showall {
    background-color: rgb(200, 202, 198);
    color: rgb(37, 36, 36);
    font-weight: bold;
    width: 10%;
    font-size: 15px;
    margin-left: 75%;

}

.grocery-card {
    display: flex;
    flex: 0 0 280px;
    border: 2px solid #67C440;
    border-radius: 10px;
    padding: 30px;
    margin: 10px;
    text-align: center;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    background-color: #fafafa;
    position: relative;
    overflow: hidden;
    width: 200px;
    cursor: pointer;
    /* Adjust according to your design */
}

.image-container img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.name-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #67C440;
    /* Adjust the background color and opacity */
    color: #fafafa;
    /* Text color */
    padding: 10px;
    transform: translateY(100%);
    transition: transform 0.3s ease;
}

.grocery-card:hover .name-overlay {
    transform: translateY(0%);
}

.grocery-card img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
}

.grocery-card h3 {
    margin: 0;
    font-size: 24px;
    color: #333;
}

.grocery-card p {
    margin: 10px 0;
    color: #555;
}

.category-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* 4 cards per row */
    gap: 20px;
    justify-content: center;
    overflow-x: auto;
    scroll-behavior: smooth;
    white-space: nowrap;
}

@media (min-width: 768px) {
    .category-container {
        grid-template-columns: repeat(4, 2fr);
        /* 4 cards per row on medium screens and above */
    }
}

.category-container h3 {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;
}

/* Home.css */

.customOverlay2 {
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px);
}

.customModal2 {
    background-color: #fafafa !important;
    padding: 20px;
    border-radius: 10px;
}

.customModal2 h2 {
    color: #368942;
    text-align: center;
    margin-bottom: 5px;
}

.customModal2 p {
    color: #202022;
    margin-bottom: 10px;
}