.cart-products{
    flex-grow: 1;
    .cart-product{
        padding: 20px 15px;
        display: flex;
        gap: 10px;
        .img-container{
            background-color: rgba(0,0,0,0.05);
            width: 60px;
            height: 60px;
            flex-shrink: 0;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .prod-details{
            overflow: hidden;
            position: relative;
            .name{
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: 16px;
                line-height: 1;
                margin-bottom: 10px;
                font-weight: 600;
                display: block;
                padding-right: 25px;
            }
            .text{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-size: 12px;
                    font-weight: 600;
                    .highlight{
                        color: #8e2de2;
                    }
             }
            svg{
                    position: absolute;
                    top: 0;
                    right: 0;
                    cursor: pointer;

            }
            .quantity-buttons{
                    width: fit-content;
                    display: flex;
                    border: 1px solid rgba(0,0,0,0.2);
                    //margin-right: 10px;
                    height: 30px;
                    margin-bottom: 8px;
                    span{
                        font-size: 14px;
                        width: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        color: #6b6b6b;
                        &:nth-child(1){
                            font-size: 18px;
                            border-right: 1px solid rgba(0,0,0,0.2);
                        }
                        
                        &:nth-child(2){
                            width : 40px;
                        }
                        
                        &:nth-child(3){
                            
                            font-size: 18px;
                            border-left: 1px solid rgba(0,0,0,0.2);
                        }
                        
                    }
                }
            }
            &:hover{
                background-color: rgba(0,0,0,0.05);
            }
        }
    }
